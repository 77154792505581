<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="1300"
      :mask-closable="false"
      title="新增项目"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <search @submit="submitSearch" />

      <a-table
        size="middle"
        :columns="tableColumns"
        :data-source="tableData"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350 }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        row-key="id"
      >
        <template slot="images" slot-scope="imgs, record">
          <!--          <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
          <img v-if="imgs && imgs.length > 0 " :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
        </template>
      </a-table>

      <a-row class="count-tips">
        共 {{ this.tableData.length }} 条记录，已选中 {{ this.selectedRowKeys.length }} 条数据
      </a-row>
    </a-modal>

    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import { findPackagesImportItems, importItems } from '@/api/packages_item'
import Search from '@/views/packages_items/Search'
import { formatCurrency } from '@/utils/filter'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'NewPackagesItems',
  components: {
    Search,
    PreviewImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    packageId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      selectedRowKeys: [],
      query: {},
      submitting: false,
      previewVisible: false,
      previewImages: [],
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '项目代码',
          dataIndex: 'code',
          width: 100
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 100
        },
        {
          title: '类型',
          dataIndex: 'item_type'
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        },
        {
          title: '价格(元)',
          dataIndex: 'price',
          customRender: formatCurrency
        },
        {
          title: '图片',
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '项目介绍',
          width: 150,
          dataIndex: 'remark',
          ellipsis: true
        }
      ],
      tableData: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findPackagesImportItems(Object.assign({}, this.query, { package_id: this.packageId })).then((res) => {
        this.tableData = res.data
        this.loading = false
      })
    },

    submitSearch(search) {
      this.query = search
      this.fetchData()
    },

    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.selectedRowKeys.length === 0) {
        this.$warning({
          title: '请选择需要新增的项目',
          content: ''
        })
        return
      }

      this.submitting = true
      importItems({ package_id: this.packageId, item_ids: this.selectedRowKeys }).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-tips {
  padding-top: 20px;
}
</style>
